import { FunctionComponent_Of_Z5A158BBF } from "../.fable/Fable.React.7.3.0/Fable.React.FunctionComponent.fs.js";
import * as react from "react";
import disable$002Dscroll from "disable-scroll";
import react$002Dmodal from "react-modal";
import { keyValueList } from "../.fable/fable-library.3.1.12/MapUtil.js";
import { Prop } from "../Wrappers/ReactModal.fs.js";
import { singleton, ofArray } from "../.fable/fable-library.3.1.12/List.js";
import { DangerousHtml, Prop as Prop_1, DOMAttr, CSSProp, HTMLAttr } from "../.fable/Fable.React.7.3.0/Fable.React.Props.fs.js";
import { ReactPlayerProp_Common$1 } from "../Wrappers/ReactPlayer.fs.js";
import vimeo from "react-player/vimeo";
import i18next from "i18next";
import { Helpers_memoEqualsButFunctions } from "../.fable/Fable.React.7.3.0/Fable.React.Helpers.fs.js";
import { Assets_InlineSVG_PlayMovieMini, Assets_SVG_Logo, Assets_Movie_JPG, Links_VimeoMovie } from "../Properties.fs.js";
import { contains } from "../.fable/fable-library.3.1.12/Set.js";
import { ModelState, Msg } from "../Model.fs.js";
import { equals } from "../.fable/fable-library.3.1.12/Util.js";
import { CommonProp, CommonTransitionProp$1, CSSTransitionProp } from "../Wrappers/ReactTransitionGroup.fs.js";
import { CSSTransition } from "react-transition-group";
import { singleton as singleton_1, append, delay, toList } from "../.fable/fable-library.3.1.12/Seq.js";
import { slot } from "../Component/AnimatedText.fs.js";
import { printf, toText } from "../.fable/fable-library.3.1.12/String.js";

export const viewVideoModal = FunctionComponent_Of_Z5A158BBF((prop) => FunctionComponent_Of_Z5A158BBF((prop_1) => {
    let styles, css, css_2, tupledArg, tupledArg_2, arg0_0;
    const state = react.useState({
        isClosing: false,
    });
    const requestClose = (e_1) => {
        const matchValue_1 = prop_1.onCloseRequest;
        if (matchValue_1 != null) {
            const onClose = matchValue_1;
            state[1](((s) => ({
                isClosing: true,
            })));
            disable$002Dscroll.off();
            void setTimeout(() => {
                onClose(e_1);
                state[1](((s_1) => ({
                    isClosing: false,
                })));
            }, 300);
        }
    };
    const handleKeyInput = (e_3) => {
        if (e_3.code === "Escape") {
            e_3.preventDefault();
            requestClose(e_3);
        }
    };
    return react.createElement(react$002Dmodal, keyValueList([new Prop(0, prop_1.isOpen), new Prop(9, (e) => {
        disable$002Dscroll.on();
        const matchValue = prop_1.onAfterOpen;
        if (matchValue != null) {
            matchValue(e);
        }
    }), new Prop(11, (e_4) => {
        requestClose(e_4);
    }), (styles = ofArray([["overlay", {
        zIndex: 1000,
        backgroundColor: "transparent",
    }], ["content", {
        backgroundColor: "transparent",
        overflowStyle: "hidden",
        border: "none",
        borderRadius: 0,
        padding: 0,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    }]]), ["style", keyValueList(styles, 1)]), new Prop(23, "Movie"), new Prop(8, (!(document == null)) ? document.body : (void 0))], 1), react.createElement("div", keyValueList([new HTMLAttr(65, "ril-outer ril__outer ril__outerAnimating " + ((state[0]).isClosing ? "ril-closing ril__outerClosing" : "")), (css = ofArray([new CSSProp(372, "opacity 300ms"), new CSSProp(9, "300ms"), new CSSProp(8, (state[0]).isClosing ? "normal" : "reverse")]), ["style", keyValueList(css, 1)]), new DOMAttr(15, handleKeyInput), new DOMAttr(17, handleKeyInput)], 1), react.createElement("div", {
        className: "ril-inner ril__inner",
        onClick: (e_2) => {
            if (e_2.target.className.indexOf("ril-inner") >= 0) {
                requestClose(e_2);
            }
        },
        style: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "50px 0px",
        },
        key: prop_1.useKey + "-inner",
    }, react.createElement("div", keyValueList([new Prop_1(0, prop_1.useKey + "-inner-video-wrapper"), (css_2 = ofArray([new CSSProp(291, "relative"), (tupledArg = [null, null, "calc(min(100vw, 177.77vh))"], new CSSProp(395, tupledArg[2])), (tupledArg_2 = [null, null, "calc(min(56.25vw, 100vh))"], new CSSProp(189, tupledArg_2[2]))]), ["style", keyValueList(css_2, 1)])], 1), (arg0_0 = ofArray([["config", keyValueList(prop_1.config, 1)], new ReactPlayerProp_Common$1(0, prop_1.url), new ReactPlayerProp_Common$1(4, prop_1.isOpen), new ReactPlayerProp_Common$1(15, true), new ReactPlayerProp_Common$1(5, true), ["width", "100%"], ["height", "100%"], new ReactPlayerProp_Common$1(13, true), new ReactPlayerProp_Common$1(14, false)]), react.createElement(vimeo, keyValueList(arg0_0, 1))))), react.createElement("div", {
        className: "ril-toolbar ril__toolbar",
        key: prop_1.useKey + "-toolbar",
    }, react.createElement("ul", {
        className: "ril-toolbar-left ril__toolbarSide ril__toolbarLeftSide",
    }), react.createElement("ul", {
        className: "ril-toolbar-right ril__toolbarSide ril__toolbarRightSide",
    }, react.createElement("li", {
        className: "ril-toolbal__item ril__toolbarItem",
    }, react.createElement("button", {
        type: "button",
        key: "close",
        ["aria-label"]: i18next.t("Close Movie"),
        className: "ril-close ril-toolbar__item__child ril__toolbarItemChild ril__builtinButton ril__closeButton",
        onClick: (e_5) => {
            if (!(state[0]).isClosing) {
                requestClose(e_5);
            }
        },
    }))))));
}, void 0, (x_8, y_2) => Helpers_memoEqualsButFunctions(x_8, y_2), (p) => p.useKey, "modalVideoPlayer", "/home/runner/work/beta/beta/src/Component/ModalVideoPlayer.fs", 31)({
    config: singleton(["playerOptions", {
        byline: false,
        dnt: true,
        responsive: true,
        title: false,
    }]),
    isOpen: prop.isOpen,
    onAfterOpen: void 0,
    onCloseRequest: (_arg1_2) => {
        prop.onClose();
    },
    url: Links_VimeoMovie,
    useKey: "17:Header.fs",
}), void 0, (x_9, y_3) => Helpers_memoEqualsButFunctions(x_9, y_3), (p_1) => p_1.key, "viewVideoModal", "/home/runner/work/beta/beta/src/View/Header.fs", 14);

export const view = FunctionComponent_Of_Z5A158BBF((props) => {
    let tupledArg, tupledArg_2, children_4, props_7, children_13, props_17;
    const logoLoaded = react.useState(false);
    const firstViewShown = react.useState(false);
    const videoRef = react.useRef(void 0);
    const videoModalShown = react.useState(false);
    react.useEffect(() => {
        const matchValue = videoRef.current;
        if (matchValue != null) {
            const video_1 = matchValue;
            video_1.volume = 0;
            video_1.defaultMuted = true;
            video_1.muted = true;
            video_1.autoplay = true;
            video_1.setAttribute("playsinline", "");
            video_1.setAttribute("disablepictureinpicture", "");
            video_1.setAttribute("preload", "true");
            void setTimeout(() => {
                video_1.play();
            }, 1000);
        }
    }, [videoRef]);
    react.useEffect(() => {
        if (contains("firstViewShown", props.completed)) {
            firstViewShown[1](true);
        }
        else if (contains("logoShown", props.completed)) {
            props.dispatch(new Msg(9, 1000, new Msg(7, "firstViewShown")));
        }
    }, [props.completed]);
    return react.createElement("header", {
        id: "header",
        className: equals(props.state, new ModelState(1)) ? "header header-loaded" : "header",
        ["data-nosnippet"]: "",
        key: "Header.fs:67",
    }, react.createElement("video", {
        className: "background-video",
        key: "background-video",
        ref: videoRef,
        muted: true,
        autoPlay: true,
        playsInline: true,
        loop: true,
        poster: Assets_Movie_JPG.src,
        onLoadedData: (_arg1) => {
            props.dispatch(new Msg(7, "backgroundVideoLoaded"));
        },
    }, react.createElement("img", keyValueList([new HTMLAttr(149, Assets_Movie_JPG.src), new HTMLAttr(152, Assets_Movie_JPG.srcSet), (tupledArg = [null, null, Assets_Movie_JPG.width], new HTMLAttr(163, tupledArg[2])), (tupledArg_2 = [null, null, Assets_Movie_JPG.height], new HTMLAttr(91, tupledArg_2[2])), new HTMLAttr(146, "100vw"), ["decoding", "async"], new HTMLAttr(158, "HTML5 not supported"), ["loading", "lazy"]], 1))), react.createElement("div", {
        id: "psuedo-darkening",
        className: "psuedo-darkening",
        key: "Header.fs:81",
    }), react.createElement("div", {
        className: "header-container",
    }, (children_4 = react.createElement("div", {
        className: "header-loading-screen",
        key: "Header.fs:89",
    }), (props_7 = ofArray([new CSSTransitionProp(0, "fade"), new CommonTransitionProp$1(4, true), new CommonTransitionProp$1(2, (!contains("logoShown", props.completed)) ? true : equals(props.state, new ModelState(0))), new CommonTransitionProp$1(0, 1000)]), react.createElement(CSSTransition, keyValueList(props_7, 1), children_4))), react.createElement("div", {
        className: "header-top",
        key: "Header.fs:91",
    }, react.createElement("p", {
        className: "header-top-text",
        key: "Header.fs:92",
    }, ...toList(delay(() => append((firstViewShown[0]) ? singleton_1("“") : singleton_1(""), delay(() => append(singleton_1(slot({
        period: ".",
        targetText: "Just a whisper...\nI hear it in my ghost",
        visible: firstViewShown[0],
    })), delay(() => ((firstViewShown[0]) ? singleton_1("”") : singleton_1("")))))))))), react.createElement("div", {
        className: "header-logo-container",
        key: "Header.fs:101",
    }, (children_13 = react.createElement("img", {
        className: "header-logo fade-init-hidden",
        src: Assets_SVG_Logo.src,
        alt: "ghostclub logo",
        width: Assets_SVG_Logo.width,
        height: Assets_SVG_Logo.height,
        onLoad: (_arg2) => {
            logoLoaded[1](true);
            props.dispatch(new Msg(9, 1000, new Msg(7, "logoShown")));
        },
    }), (props_17 = ofArray([new CSSTransitionProp(0, "fade"), new CommonProp(0, true), ["timeout", {
        appear: 1000,
        enter: 1000,
        exit: 0,
    }], new CommonTransitionProp$1(2, logoLoaded[0])]), react.createElement(CSSTransition, keyValueList(props_17, 1), children_13)))), react.createElement("div", {
        className: "header-bottom",
        key: "Header.fs:118",
    }, react.createElement("div", {
        className: "header-bottom-playbutton-container is-hidden-mobile",
        key: "Header.fs:119",
    }, react.createElement("div", {
        className: "header-bottom-playbutton",
        dangerouslySetInnerHTML: new DangerousHtml(Assets_InlineSVG_PlayMovieMini),
        onClick: (_arg3) => {
            videoModalShown[1](true);
        },
    })), react.createElement("div", {
        className: "header-bottom-scroll-indicator is-hidden-mobile",
        key: "Header.fs:128",
    }, react.createElement("p", {
        className: "header-bottom-scroll-indicator-text",
        key: "Header.fs:129",
    }, react.createElement("span", {
        style: {
            marginRight: "1em",
        },
    }, "Scroll Down"), react.createElement("span", {
        style: {
            fontSize: "1.75vh",
        },
    }, "━━━━━━━━"))))), viewVideoModal({
        isOpen: videoModalShown[0],
        key: "138:Header.fs",
        onClose: () => {
            videoModalShown[1](false);
        },
    }));
}, void 0, (x_8, y_2) => Helpers_memoEqualsButFunctions(x_8, y_2), (_arg4) => toText(printf("%s:%s"))("Header.fs")("141"), "view", "/home/runner/work/beta/beta/src/View/Header.fs", 32);

